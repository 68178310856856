<template>
  <SMListTemplate prefix="Tanımlar" title="Sınıflar">
    <template #actions>
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          dark
          :disabled="!isSalePeriodSelected"
          class="mr-2"
          @click="showDeleteRecordModal"
        >
          mdi-delete
        </v-icon>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cursor-pointer"
              dark
              @click="() => fetchSmClasses()"
              v-bind="attrs"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          dark
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
        <router-link
          :to="{ name: 'def-sm-classes-edit', query: { editable: true } }"
        >
          <v-btn elevation="0" color="success">
            <v-icon small>mdi-plus</v-icon>
            Yeni Sınıf
          </v-btn>
        </router-link>
      </div>
    </template>
    <template #table>
      <v-data-table
        v-model="selectedRecord"
        :headers="headers"
        :items="mappedSmClasses"
        :search="search"
        :loading="loading"
        :items-per-page="100"
        item-key="id"
        single-select
        show-select
      >
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{
                  name: 'def-sm-classes-edit',
                  params: { id: item.id },
                  query: { editable: true },
                }"
              >
                <v-icon color="warning" small v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template #item.name="{ item }">
          <router-link
            :to="{
              name: 'def-sm-classes-edit',
              params: { id: item.id },
              query: { editable: false },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #item.education_display="{ item }">
          <router-link
            v-if="item.education_id"
            :to="{
              name: 'def-trainings-edit',
              params: { id: item.education.id },
              query: { editable: false },
            }"
          >
            {{ item.education_display }}
          </router-link>
          <span v-else>{{ item.education_display }}</span>
        </template>
        <template #item.registration_period_display="{ item }">
          <router-link
            v-if="item.registration_period_id"
            :to="{
              name: 'def-trainings-edit',
              params: { id: item.registration_period?.id },
              query: { editable: false },
            }"
          >
            {{ item.registration_period_display }}
          </router-link>
          <span v-else>{{ item.registration_period_display }}</span>
        </template>
        <template #item.registration_period_display="{ item }">
          <router-link
            v-if="item.registration_period_id"
            :to="{
              name: 'def-trainings-edit',
              params: { id: item.registration_period?.id },
              query: { editable: false },
            }"
          >
            {{ item.registration_period_display }}
          </router-link>
          <span v-else>{{ item.registration_period_display }}</span>
        </template>
        <template #item.instructor_display="{ item }">
          <router-link
            v-if="item.instructor_id"
            :to="{
              name: 'user-show',
              params: { id: item.instructor_id },
              query: { editable: false },
            }"
          >
            {{ item.instructor_display }}
          </router-link>
          <span v-else>{{ item.instructor_display }}</span>
        </template>
        <template #item.assistant_display="{ item }">
          <router-link
            v-if="item.instructor_id"
            :to="{
              name: 'user-show',
              params: { id: item.assistant_id },
              query: { editable: false },
            }"
          >
            {{ item.assistant_display }}
          </router-link>
          <span v-else>{{ item.assistant_display }}</span>
        </template>
      </v-data-table>
    </template>
  </SMListTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  FETCH_SM_CLASSES,
  DELETE_SM_CLASS,
} from "@/core/services/store/definitions/sm-classes.module";

import SMListTemplate from "@/view/content/components/SMListTemplate.vue";

export default {
  name: "SalePeriodList",
  components: { SMListTemplate },
  data() {
    return {
      headers: [
        {
          value: "name",
          text: "Sınıf Adı",
          align: "left",
        },
        {
          value: "code",
          text: "Sınıf Kodu",
          align: "left",
        },
        {
          value: "education_display",
          text: "Eğitim",
          align: "left",
        },
        {
          value: "registration_period_display",
          text: "Kayıt Dönemi",
          align: "left",
        },
        {
          value: "instructor_display",
          text: "Eğitmen",
          align: "left",
        },
        {
          value: "assistant_display",
          text: "Asistan",
          align: "left",
        },
        {
          value: "capacity",
          text: "Kapasite",
          align: "left",
        },
        {
          value: "description",
          text: "Açıklama",
          align: "left",
        },
        { value: "starts_on", text: "Başlangıç Tarihi", align: "left" },
        { value: "ends_on", text: "Bitiş Tarihi", align: "left" },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "left",
        },
        { text: "", value: "data-table-select", align: "right" },
      ],
      loading: true,
      selectedRecord: [],
      search: "",
    };
  },
  async mounted() {
    try {
      await this.fetchSmClasses();
    } catch (error) {
      let message =
        error[0]?.detail ?? "Bir hata oluştu lütfen tekrar deneyiniz";
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 2000,
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([FETCH_SM_CLASSES, DELETE_SM_CLASS]),
    showDeleteRecordModal() {
      const params = {
        title: "Seçtiğiniz sınıf silinecek",
        text: "Seçili sınıfı silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptRecordDelete();
        },
      };
      this.$approvalModal.show(params);
    },
    async acceptRecordDelete() {
      try {
        await this.deleteSmClass(this.selectedRecord[0]);
        this.$toast.success("Sınıf silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedRecord = [];
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["smClasses"]),
    isSalePeriodSelected() {
      return this.selectedRecord.length === 1;
    },
    mappedSmClasses() {
      return this.smClasses.map((smClass) => {
        return {
          ...smClass,
          assistant_display: smClass.assistant?.name ?? "Asistan Yok",
          instructor_display: smClass.instructor?.name ?? "Eğitmen Yok",
          registration_period_display:
            smClass.registration_period?.name ?? "Kayıt Dönemi Yok",
          education_display: smClass.education?.name ?? "Eğitim Yok",
          ends_on: this.$moment(smClass.ends_on).format("DD/MM/YYYY"),
          description: smClass.description ?? "Açıklama Yok",
          starts_on: this.$moment(smClass.starts_on).format("DD/MM/YYYY"),
        };
      });
    },
  },
};
</script>
